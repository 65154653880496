// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import AdBanner from './Adbanner';
import './App.css';

function App() {
  const [userId, setUserId] = useState("user123");
  const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [status, setStatus] = useState(''); 
    const [queuePosition, setQueuePosition] = useState(null);
    const [queueLength, setQueueLength] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentRequestId, setCurrentRequestId] = useState(null);
    const [activeStream, setActiveStream] = useState(null);

    useEffect(() => {
      let savedUserId = localStorage.getItem('user_id');
      if (!savedUserId) {
          savedUserId = crypto.randomUUID();  // Generates a unique ID
          localStorage.setItem('user_id', savedUserId);
      }
      setUserId(savedUserId);
  }, []);
    // Custom headers
    const headers = { 'X-Requested-With': 'gptinprivate-client' }; // basic identifier

    const cancelRequest = async () => {
      try {
        await axios.post('https://api.gptinprivate.com/api/leave_queue', { user_id: userId }, { headers });
        // Cancel the active stream if it exists
        if (activeStream) {
            activeStream.abort(); // Abort the active stream
            setActiveStream(null);
        }
        setQueuePosition(null);
        setQueueLength(null);
        setStatus('');
        setIsProcessing(false);
        setResponse(''); // Clear the response
        setCurrentRequestId(null); // Reset the current request ID
      } catch (error) {
        console.error("Error cancelling request", error);
      }
    };

    const handleSendMessage = async () => {
      if (queuePosition !== null) {
        await cancelRequest();
      }
      
      try {
        setIsProcessing(true);
        const res = await axios.post('https://api.gptinprivate.com/api/join_queue', { user_id: userId }, { headers });
        setQueuePosition(res.data.queue_position);
        setQueueLength(res.data.queue_length);
        checkQueuePosition();
      } catch (error) {
        console.error("Error joining queue", error);
        setResponse("Error joining the queue. Please try again later.");
        setIsProcessing(false);
      }
    };

    const checkQueuePosition = async () => {
        try {
            const res = await axios.post('https://api.gptinprivate.com/api/check_queue', { user_id: userId }, { headers });
            setQueuePosition(res.data.queue_position);
            setQueueLength(res.data.queue_length);

            if (res.data.ready) {
                processPrompt();
            } else {
                setTimeout(checkQueuePosition, 3000);
            }
        } catch (error) {
            console.error("Error checking queue position", error);
            setTimeout(checkQueuePosition, 3000);
        }
    };

    const processPrompt = async () => {
        setResponse('');
        setStatus('Generating response...');
        
        const requestId = crypto.randomUUID();
        //console.log('Generated request ID:', requestId);
        setCurrentRequestId(requestId);

        const controller = new AbortController();
        setActiveStream(controller);

        try {
            const response = await fetch('https://api.gptinprivate.com/api/generate_response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'gptinprivate-client'
                },
                body: JSON.stringify({ 
                    user_id: userId, 
                    prompt: message,
                    request_id: requestId
                }),
                signal: controller.signal
            });
      
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
      
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';

            try {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
          
                    // Decode the chunk and add it to our buffer
                    buffer += decoder.decode(value, { stream: true });
                    
                    // Split on double newlines (SSE format)
                    const parts = buffer.split('\n\n');
                    
                    // Process all complete messages
                    for (let i = 0; i < parts.length - 1; i++) {
                        const part = parts[i];
                        if (!part.trim()) continue;
                        
                        if (part.startsWith('data: ')) {
                            try {
                                const jsonStr = part.replace('data: ', '').trim();
                                //console.log('Processing JSON:', jsonStr); // Debug log
                                
                                const parsedChunk = JSON.parse(jsonStr);
                                //console.log('Parsed chunk:', parsedChunk); // Debug log
                                
                                if (parsedChunk.request_id === requestId) {
                                    if (parsedChunk.error) {
                                        setStatus(`Error: ${parsedChunk.error}`);
                                        break;
                                    }
                                    if (parsedChunk.response) {
                                        setResponse(prev => {
                                            const newResponse = prev + parsedChunk.response;
                                            //console.log('Updated response:', newResponse); // Debug log
                                            return newResponse;
                                        });
                                    }
                                }
                            } catch (err) {
                                console.error('Parse error:', err);
                            }
                        }
                    }
                    
                    // Keep the last partial message in the buffer
                    buffer = parts[parts.length - 1];
                }
                
                // Process any remaining buffer content
                if (buffer.trim()) {
                    const part = buffer.trim();
                    if (part.startsWith('data: ')) {
                        try {
                            const jsonStr = part.replace('data: ', '').trim();
                            const parsedChunk = JSON.parse(jsonStr);
                            if (parsedChunk.request_id === requestId && parsedChunk.response) {
                                setResponse(prev => prev + parsedChunk.response);
                            }
                        } catch (err) {
                            console.error('Parse error on final chunk:', err);
                        }
                    }
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Stream was cancelled');
                    return;
                }
                throw error;
            }
      
            setStatus('');
            setIsProcessing(false);
            setActiveStream(null);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was cancelled');
                return;
            }
            console.error('Streaming error:', error);
            setStatus('Error with streaming response. Please try again.');
            setQueuePosition(null);
            setQueueLength(null);
            setIsProcessing(false);
            setActiveStream(null);
        }
    };

    // Add cleanup effect
    useEffect(() => {
        return () => {
            if (activeStream) {
                activeStream.abort();
            }
        };
    }, [activeStream]);

    return (
        <div className="App">
            <Navbar />
            <div className="container">
                <AdBanner position="top" />
                <div className="chat-container">
                    <AdBanner position="left" />
                    <div className="chatbox">
                      <div className="status-message">
                          <p>{status}</p>
                      </div>

                        <div className="chat-messages">
                            <p>Bot: {response}</p>
                        </div>
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message..."
                            disabled={isProcessing}
                        />
                        <button 
                            onClick={handleSendMessage} 
                            disabled={isProcessing}
                        >
                            Send
                        </button>
                        <div className="queue-info">
                            {queuePosition !== null && (
                                <>
                                    <p>Queue Position: {queuePosition}/{queueLength}</p>
                                    <button onClick={cancelRequest}>Cancel Request</button>
                                </>
                            )}
                        </div>
                    </div>
                    <AdBanner position="right" />
                </div>
                <AdBanner position="bottom" />
            </div>
        </div>
    );
}

export default App;
